/*
#0D6EFD rgb(13, 110, 253) Blue
#198754 rgb(25, 135, 84) Green
#FFC107 rgb(255, 193, 7) Yellow
*/

@font-face {
  font-family: 'comfortaaregular';
  src: url('./fonts/comfortaa-variablefont_wght-webfont.woff2') format('woff2'),
  url('./fonts/comfortaa-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ag-theme-alpine .ag-root-wrapper {
  border-width: 0px;
}

.ag-theme-alpine .ag-header {
  border-width: 0px;
}

.ag-theme-alpine {
  --ag-background-color: transparent;
}

html {
  position: relative;
  min-height: 100%;
}

a:not([href]) {
  cursor: pointer;
}

body {
  background: url("./images/light-bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  margin: 0 0 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  font-family: 'comfortaaregular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-card{
  min-height: 85vh;
}

.logout-button-wrapper button {
  max-height:25px;
}

.header-column{
  font-family: 'comfortaaregular';
  font-weight:bold;
  font-color:#000000;
  background: rgb(255,193,7);
  background: linear-gradient(90deg, rgba(255,193,7,0.3) 0%, rgba(255,193,7,.22) 50%, rgba(255,193,7,0.3) 100%);
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.header-column h2{
  font-size:1.7em!important;
}

.menu-column {
  font-family: 'comfortaaregular';
  font-weight:bold;
  font-color:#000000;
  background: rgb(255,193,7);
  background: linear-gradient(90deg, rgba(255,193,7,0.3) 0%, rgba(255,193,7,.22) 50%, rgba(255,193,7,0.3) 100%);
}

.blue-body {
  background: linear-gradient(90deg, rgba(13,110,253,0.25) 0%, rgba(13,110,253,0.15) 50%, rgba(13,110,253,0.25) 100%);
}

.card-footer {
  background-color: rgba(255, 255, 255, 1);
}

.table-editable td{
  height:10px;
}

.card-disabled {
  pointer-events: none;
  background-color: rgba(0,0,0,.2);
}

.btn-disabled {
  pointer-events: none;
  background-color: rgba(0,0,0,.2);
}

.disabled {
  pointer-events: none;
}

.dropzone {
  border: 3px dashed #999;
  cursor: pointer;
}

.temporary-attachment-table {
  background-color: rgba(255,255,255,.4);
  border-radius: 5px;

}
input,select,button,textarea,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-link:focus,
.btn-link:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-dark:focus,
.btn-dark:active
{
  box-shadow:none !important;
  outline:0px !important;
}

textarea {
  resize: none;
}

.font-small {
  font-size: .85em !important;
}

.ql-container {
  min-height:30vh;
  padding:2px;
}

.ql-picker-label{
  text-align:left;
}
.table .form-control{
  background-color: white;
}

.div-stack {
  grid-area: inner-div;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: crisp-edges;
}

.display-none{
 display:none;
}

.input-group-append .btn {
  background-color: transparent;
}

.input-group-append .btn:hover {
  cursor: pointer!important;
  color:rgb(146,190,254)!important;
}

.ag-cell-wrapper{
  height:35px;
}

.ag-header-cell-label, .ag-cell-value {
  font-size: 12px;
}

.ag-root-wrapper.ag-layout-normal {
  height: 95%!important;
}

/*MODAL STYLES*/

.popup-content{
  border-radius:5px;
  max-width:600px;
}
.tag-modal {
  font-size: 1em;
}
.tag-modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.tag-modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.tag-modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.tag-modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
}

.rs__cell>button {
  cursor: default!important;
  pointer-events: none;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.footer{
  position:absolute;
  width:100%;
  bottom:0;
}
